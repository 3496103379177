<template>
  <div style="margin-top: 100px">
    <div v-for="text in data.AboutUs" :key="text"></div>
    <div class="htmlabout" v-html="data.AboutUsHtml"></div>
  </div>
</template>
<script>
import { data } from "../App.vue";
export default {
  /* eslint-disable */
  data() {
    return {
      data: data,
    };
  },
};
</script>

<style lang="scss">
.center {
  text-align: center;
}
.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-about {
  height: 700px !important;
  width: auto;
  border-radius: 20px;
}
.htmlabout .text-about {
  max-width: 720px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Poppins-Light;
  .bold-text {
    font-family: Poppins-Medium;
    display: block;
    width: 500px !important;
  }
}
</style>