import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import { vMaska } from "maska";
import "bootstrap/dist/css/bootstrap.css";
import "aos";
import "aos/dist/aos.css";
import mitt from "mitt";

const emitter = mitt();
const app = createApp(App).directive("maska", vMaska);

app.config.globalProperties.emitter = emitter;
app.use(router);
app.use(VueScrollTo);
app.mount("#app");
