<template>
  <div v-if="isLoading" class="flex-center">
    <div class="load-app">
      <div class="loadd">
        <div class="spinner-border spin" role="status"></div>
      </div>
      <h3 class="ssda">Carregando</h3>
      <p style="text-aling: center">
        Carregando dados da pagina, por favor, aguarde.
      </p>
    </div>
  </div>
  <div v-else class="div-pai">
    <NavBar @price-scroll="ativarScroll"></NavBar>
    <router-view class="Route"></router-view>
  </div>
</template>

<script>
import NavBar from "../src/components/NavBar.vue";
import { ref, defineComponent } from "vue";
import Products from "./services/products.js";
import dataJson from "../public/data.json";
export const data = ref([]);
import Swal from "sweetalert2";

export default defineComponent({
  name: "app",

  components: {
    NavBar,
  },
  data() {
    return {
      scrollOn: false,
      ApiDescription: "DAD",
      ApiId: "",
      filteredItem: null,
      data: [],
      isLoading: true,
    };
  },
  created() {
    this.getDataApi();
    // this.recive();
  },
  methods: {
    ativarScroll() {
      console.log("chegou em app.vue");
    },
    recive() {
      this.isLoading = false;
      data.value = dataJson;
    },
    getDataApi() {
      if (dataJson) {
        this.recive();
      } else
        Products.getApi()
          .then((response) => {
            if (response && response.data) {
              this.filteredItem = response.data.filter(
                (item) => item.description === this.ApiDescription
              );

              this.ApiId = this.filteredItem[0].product_id;
              this.filteredItem = this.ApiId;
            }
            Products.listApi(this.ApiId).then((response) => {
              data.value = response.data;
              this.isLoading = false;
            });
          })
          .catch((error) => {
            setTimeout(() => {
              this.getDataApi();
            }, 10000);

            Swal.fire({
              title: "Não foi possivel carregar dados",
              text:
                "Não foi possivel encontrar o produto " +
                this.ApiDescription +
                ". Errro:" +
                error,
              icon: "warning",
              confirmButtonText: "Fechar",
              confirmButtonColor: "#d33",
            });

            setTimeout(() => {
              this.getDataApi();
            }, 10000);
          });
    },
  },
});
</script>

<style>
html {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("components/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Quicksand";
  src: url("components/fonts/Quicksand_Light.otf");
}

@font-face {
  font-family: "components/Poppins-Light";
  src: url("components/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("components/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("components/fonts/Poppins-Regular.ttf");
}
.load-app {
  margin-top: 3rem;
  border: 1px solid rgba(203, 203, 203, 0.791);
  box-shadow: 1px 2px 12px 2px rgba(0, 0, 0, 0.121);
  border-radius: 5px;
  padding: 40px;
  background-color: rgb(249, 249, 249);
}
.flex-center {
  justify-content: center;
  display: flex;
}
.loadd {
  justify-content: center;
  display: flex;
}

.ssda {
  text-align: center;
}

.spin {
  font-size: 1rem;
  --bs-spinner-width: 3rem !important;
  --bs-spinner-height: 3rem !important;
  margin-bottom: 10px;
}
</style>