// scrollRevealMixin.js
import ScrollReveal from 'scrollreveal';

export default {
  mounted() {
    this.bottom()
    this.left()
    this.right()
  },
  methods: {
    bottom() {
    this.revealBottom = ScrollReveal({
      reset: true,  // Permite que a animação seja repetida ao reentrar na viewport
      distance: '100px',
      duration: 2500,
      delay: 200,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      origin: 'bottom',
      opacity: 0,
      interval: 150
    });

    const reveal_bottom = document.querySelectorAll('.reveal_bottom')
        reveal_bottom.forEach(el => {
          this.revealBottom.reveal(el);
        });
    },
    left() {
      
      this.revealLeft = ScrollReveal({
        reset: true,  // Permite que a animação seja repetida ao reentrar na viewport
        distance: '100%',
        duration: 2500,
        delay: 200,
        easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
        origin: 'left',
        opacity: 0,
        interval: 150
      });
    
    
      const reveal_left = document.querySelectorAll('.reveal_left')
    
    
          reveal_left.forEach(el => {
            this.revealLeft.reveal(el);
          });
        

    },

     right() {
      
      this.reveal_right = ScrollReveal({
        reset: true,  // Permite que a animação seja repetida ao reentrar na viewport
        distance: '100%',
        duration: 2500,
        delay: 200,
        easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
        origin: 'right',
        opacity: 0,
        interval: 150
      });
    
    
      const reveal_right = document.querySelectorAll('.reveal_right')
    
    
          reveal_right.forEach(el => {
            this.reveal_right.reveal(el);
          });
        

    },
  },

};
