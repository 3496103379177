import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutUs from "../components/AboutUs.vue";
import ContractView from "../views/ContractView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/sobre-nos",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/contract/:id?",
    name: "contract",
    component: ContractView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
